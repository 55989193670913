import React from "react";
import SbEditable from 'storyblok-react';

export default function withSbEditable(Component: any) {
  return (props: any) => (
    <SbEditable key={props._uid} content={props}>
      <Component {...props} />
    </SbEditable>
  );
}
